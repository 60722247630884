import { Injectable } from '@angular/core';
import { environment } from './environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor() { }

  getPortegoWebserviceUrl() {
    if (environment.portegoWebserviceUrl == null) {
       throw new Error('PortegoWebserviceUrl is not defined');
    }

    return environment.portegoWebserviceUrl;
  }

  getOpenWeatherMapApiKey() {
    if (environment.openWeatherMapApiKey == null){
      throw new Error('OpenWeatherMapApiKey is not defined');
    }

    return environment.openWeatherMapApiKey;
  }
}
