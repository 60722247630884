import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { QRCodeModule } from 'angularx-qrcode';
import { AppComponent } from './app.component';
import { HouseNumberPlaqueComponent } from './components/house-number-plaque/house-number-plaque.component';
import { HomeComponent } from './views/home/home.component';
import { TileComponent } from './components/tile/tile.component';
import { DayOverviewTileComponent } from './components/day-overview-tile/day-overview-tile.component';
import { DayNamesMottoTileComponent } from './components/day-names-motto-tile/day-names-motto-tile.component';
import { ContactTileComponent } from './components/contact-tile/contact-tile.component';
import { TopicTileComponent } from './components/topic-tile/topic-tile.component';
import { TopicListTileComponent } from './components/topic-list-tile/topic-list-tile.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { BuildingSearchComponent } from './views/building-search/building-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppointmentTileComponent } from './components/appointment-tile/appointment-tile.component';
import { AppointmentListTileComponent } from './components/appointment-list-tile/appointment-list-tile.component';
import { PrivacyBannerComponent } from './components/privacy-banner/privacy-banner.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ImprintComponent } from './views/imprint/imprint.component';
import { FooterComponent } from './components/footer/footer.component';
import { BackbuttonComponent } from './components/backbutton/backbutton.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { GlobalErrorHandler } from './handler/global-error.handler';
import { LinksTileComponent } from './components/links-tile/links-tile.component';
import { CanvasClockComponent } from './components/canvas-clock/canvas-clock.component';
import { SubscribeTileComponent } from './components/subscribe-tile/subscribe-tile.component';
import { SubscriptionComponent } from './views/subscription/subscription.component';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselComponent } from './components/carousel/carousel.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KioskModeComponent } from './views/kiosk-mode/kiosk-mode.component';
import { KioskModalComponent } from './components/kiosk-modal/kiosk-modal.component';
import { QrcodeTileComponent } from './components/qrcode-tile/qrcode-tile.component';
import { ConfigurationService } from 'src/configuration.service';
import { PortegoService } from './services/portego.service';
import { DownloadService } from './services/download.service';
import { KioskModeService } from './services/kiosk-mode.service';
import { AppoinmentIconService } from './services/appointment-icon.service';
import { AnouncementIconService } from './services/announcement-icon.service';

@NgModule({
  declarations: [
    AppComponent,
    HouseNumberPlaqueComponent,
    HomeComponent,
    TileComponent,
    DayOverviewTileComponent,
    DayNamesMottoTileComponent,
    ContactTileComponent,
    TopicTileComponent,
    TopicListTileComponent,
    ScrollToTopComponent,
    BuildingSearchComponent,
    AppointmentTileComponent,
    AppointmentListTileComponent,
    PrivacyBannerComponent,
    PrivacyPolicyComponent,
    ImprintComponent,
    FooterComponent,
    BackbuttonComponent,
    NoDataComponent,
    LinksTileComponent,
    CanvasClockComponent,
    SubscribeTileComponent,
    SubscriptionComponent,
    CarouselComponent,
    KioskModeComponent,
    KioskModalComponent,
    QrcodeTileComponent,
  ],
  entryComponents: [
    KioskModalComponent
  ],
  exports: [
    KioskModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCarouselModule,
    NgbModule,
    BrowserAnimationsModule,
    QRCodeModule,
  ],
  providers: [{
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  },
    ConfigurationService,
    PortegoService,
    DownloadService,
    KioskModeService,
    AppoinmentIconService,
    AnouncementIconService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
