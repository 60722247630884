export const environment = {
  production: true,
  isDemo: false,
  useBuildingNumberAsBoardKey: true,
  singleBuildingModeBoardKey: '00023',
  portegoWebserviceUrl: 'https://portego.floriette.at/api/',
  openWeatherMapApiKey: 'e7f0fd2faef4b9228cc30e108da1762f',
  htmlDocumentTitle: 'Schwarzes Brett',
  hideCompanyLogo: false,
  links: []
};
