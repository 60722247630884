import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KioskModeService } from 'src/app/services/kiosk-mode.service';

@Component({
  selector: 'app-kiosk-mode',
  templateUrl: './kiosk-mode.component.html',
  styleUrls: ['./kiosk-mode.component.less'],
})
export class KioskModeComponent implements OnInit {

  constructor(private kioskModeService: KioskModeService,
    private router: Router) {
  }

  ngOnInit() {
    this.kioskModeService.activateKioskMode();
    this.router.navigateByUrl('');
  }

}
